import { Button } from '@/components/Button';
import { MorePagesTitle } from '@/components/MorePagesTitle';
import { Spinner } from '@/components/Spinner';
import { useInfinityScrollFront } from '@/hooks/useInfinityScrollFront';
import { Content } from '@/types/content';
import { initAdunitElements } from '@/utils/initAdunitElements';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { Fragment, useDeferredValue, useEffect, useRef } from 'react';

const PAGES_TO_AUTOLOAD = 2;

export const InfinityScrollFront = ({ pageData, sourceRoute }: { pageData: Content; sourceRoute: string }) => {
  const markerRef = useRef<HTMLDivElement>(null);

  const { loading, pages, loadNextPage, endReached } = useInfinityScrollFront({
    sourceRoute,
    pagesToAutoLoad: PAGES_TO_AUTOLOAD,
    pageData,
    intersectionTargetElement: markerRef,
  });

  const lastPage = useDeferredValue(pages[pages.length - 1]);

  useEffect(() => {
    if (lastPage) {
      initAdunitElements(lastPage.meta.adsUniqueId, lastPage.meta.isNativeAd);
    }
  }, [lastPage]);

  return (
    <>
      {pages.length > 0 && <MorePagesTitle />}
      {pages.map((page, index) => (
        <Fragment key={index}>{renderPostTypeComponent(page)}</Fragment>
      ))}

      {pages.length >= PAGES_TO_AUTOLOAD && !loading && !endReached && (
        <Button content="Mer" options={{ onClick: loadNextPage, className: '!flex mx-auto my-10' }} />
      )}

      {loading && <Spinner />}
      <div ref={markerRef} className="relative bottom-[300vh]" />
    </>
  );
};
