/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneSpinner as ResolvedStandaloneSpinner } from 'base/components/standalone/Spinner';

export const Spinner: typeof ResolvedStandaloneSpinner = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneSpinner {...props} />
        </ErrorBoundary>
    );
});

export type SpinnerProps = PropsFromComponent<typeof Spinner>;

/** @deprecated Use slot architecture instead */
export const SpinnerWith = (extras: DynamicStandaloneExtras): typeof Spinner => {
    return function Spinner(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Spinner');
        return <ResolvedStandaloneSpinner {...mergeProps({ options: { theme } }, props)} />;
    }
}