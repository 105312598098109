import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { ReactNode } from 'react';
import { Spinner } from './Spinner';

export interface StandaloneSpinnerProps extends StandaloneComponentProps {
  loader?: ReactNode;
}

export const StandaloneSpinner: StandaloneComponent<StandaloneSpinnerProps> = ({ loader, ...props }) => {
  return (
    <Spinner {...props}>
      <Spinner.Loader>{loader}</Spinner.Loader>
    </Spinner>
  );
};
