import { SpinnerTheme } from '@/components/Spinner/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: SpinnerTheme });

const Base = $({ as: 'div', slot: 'base' });
const Loader = $({ as: 'div', slot: 'loader' });

export const Spinner = Object.assign(Base, {
  Loader,
});
