import type { Content } from '@/types/content';

export function removeBySelectors(selectors: string[], children: Content[]): Content[] {
  return children.filter((child) => {
    if (selectors.includes(child.meta.selector)) return false;

    if (child.children?.length > 0) {
      child.children = removeBySelectors(selectors, child.children);
    }

    return true;
  });
}

export function removeBySelector(selector: string, children: Content[]): Content[] {
  return removeBySelectors([selector], children);
}
