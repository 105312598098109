import { tw } from '@/utils/tw';

const Spinner = tw.theme({
  slots: {
    base: `my-16 flex items-center justify-center`,
    loader: `h-24 w-24 animate-spin rounded-full border-b-4 border-gray-900`,
  },
});

export default Spinner;
