import { tw } from '@/utils/tw';

const Tabs = tw.theme({
  slots: {
    base: `bg-secondary-400 mx-auto inline-flex flex-row items-center justify-center gap-1 rounded-md p-1`,
    tab: `bg-secondary-100 data-[active]:bg-primary-300 flex flex-row items-center gap-0.5 rounded-md px-4 py-2 transition-colors data-[locked]:pointer-events-none data-[locked]:text-gray-600`,
  },
});

export default Tabs;
