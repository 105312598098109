/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTabs as ResolvedStandaloneTabs } from 'base/components/standalone/Tabs';

export const Tabs: typeof ResolvedStandaloneTabs = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTabs {...props} />
        </ErrorBoundary>
    );
});

export type TabsProps = PropsFromComponent<typeof Tabs>;

/** @deprecated Use slot architecture instead */
export const TabsWith = (extras: DynamicStandaloneExtras): typeof Tabs => {
    return function Tabs(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Tabs');
        return <ResolvedStandaloneTabs {...mergeProps({ options: { theme } }, props)} />;
    }
}