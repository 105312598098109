
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[device]/front/[[...params]]",
      function () {
        return require("private-next-pages/[device]/front/[[...params]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[device]/front/[[...params]]"])
      });
    }
  