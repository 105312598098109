import type { OptionalLinkProps } from '@/components/OptionalLink';
import { OptionalLink } from '@/components/OptionalLink';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { MdLockOutline } from 'react-icons/md';
import { Tabs } from './Tabs';

interface ExtendedOptions {
  $link?: OptionalLinkProps['options'];
}

export interface StandaloneTabsProps extends StandaloneComponentProps<typeof Tabs, ExtendedOptions> {
  tabs?: (OptionalLinkProps & {
    isActive?: boolean;
    isLocked?: boolean;
  })[];
}

export const StandaloneTabs: StandaloneComponent<StandaloneTabsProps> = ({ tabs, ...props }) => {
  return (
    <Tabs {...props}>
      {(tabs ?? []).map(({ content, isActive, isLocked, ...link }, index) => {
        return (
          <OptionalLink
            key={index}
            content={
              <Tabs.Tab data-active={isActive ? '' : undefined} data-locked={isLocked ? '' : undefined}>
                {isLocked && <MdLockOutline />}
                {content}
              </Tabs.Tab>
            }
            options={props?.options?.$link}
            {...link}
          />
        );
      })}
    </Tabs>
  );
};
