import { tw } from '@/utils/tw';
import BaseTabsTheme from 'base/components/Tabs/theme';

const Tabs = tw.theme({
  extend: BaseTabsTheme,
  variants: {
    size: {
      small: {
        base: `gap-0.5 rounded-lg p-0.5`,
        tab: `rounded-md px-2 py-1.5 !leading-none text-headline-xs`,
      },
      large: {
        base: `gap-1 rounded-xl p-1`,
        tab: `rounded-lg px-6 py-3 text-headline-sm data-[active]:font-bold`,
      },
    },
    colors: {
      default: {
        base: `bg-yellow-sea-400`,
        tab: `bg-none data-[active]:bg-white data-[locked]:opacity-30 data-[active]:shadow-[inset_0_0_3px_0_rgba(0,0,0,0.4)] hover:bg-white/80`,
      },
      alternative: {
        base: `bg-white`,
        tab: `bg-none data-[active]:bg-yellow-sea-400 data-[locked]:opacity-30 data-[active]:shadow-[inset_0_0_3px_0_rgba(0,0,0,0.4)] hover:bg-yellow-sea-400/80`,
      },
    },
  },
  defaultVariants: {
    size: 'small',
    colors: 'default',
  },
});

export default Tabs;
