/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneOptionalLink as ResolvedStandaloneOptionalLink } from 'base/components/standalone/OptionalLink';

export const OptionalLink: typeof ResolvedStandaloneOptionalLink = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneOptionalLink {...props} />
        </ErrorBoundary>
    );
});

export type OptionalLinkProps = PropsFromComponent<typeof OptionalLink>;

/** @deprecated Use slot architecture instead */
export const OptionalLinkWith = (extras: DynamicStandaloneExtras): typeof OptionalLink => {
    return function OptionalLink(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'OptionalLink');
        return <ResolvedStandaloneOptionalLink {...mergeProps({ options: { theme } }, props)} />;
    }
}